import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

import { EstabelecimentoProvider } from "./context/EstabelecimentoContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <EstabelecimentoProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </EstabelecimentoProvider>
  </React.StrictMode>
);

registerServiceWorker();
