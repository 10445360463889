import React, { useState } from "react";
import { toast } from "react-hot-toast";

const HorariosAtendimento = ({ horarios = {}, onSaveHorarios }) => {
  const inicializarHorarios = () => {
    const defaultHorarios = {
      domingo: { horaInicio: "00:00", horaFim: "00:00" },
      segunda: { horaInicio: "00:00", horaFim: "00:00" },
      terca: { horaInicio: "00:00", horaFim: "00:00" },
      quarta: { horaInicio: "00:00", horaFim: "00:00" },
      quinta: { horaInicio: "00:00", horaFim: "00:00" },
      sexta: { horaInicio: "00:00", horaFim: "00:00" },
      sabado: { horaInicio: "00:00", horaFim: "00:00" },
    };

    const diasSelecionados = {
      domingo: false,
      segunda: false,
      terca: false,
      quarta: false,
      quinta: false,
      sexta: false,
      sabado: false,
    };

    Object.keys(horarios).forEach((horarioKey) => {
      const [horaInicio, horaFim] = horarios[horarioKey].split("|") || ["00:00", "00:00"];;
      const dia = horarioKey.replace("horario_", "");

      if (defaultHorarios[dia]) {
        defaultHorarios[dia] = { horaInicio, horaFim };
        diasSelecionados[dia] = true;
      }
    });

    return { defaultHorarios, diasSelecionados };
  };

  const { defaultHorarios, diasSelecionados: inicialDiasSelecionados } =
    inicializarHorarios();

  const [disponibilidadePorDia, setDisponibilidadePorDia] =
    useState(defaultHorarios);
  const [diasSelecionados, setDiasSelecionados] = useState(
    inicialDiasSelecionados
  );

  const diaMappingDisplay = {
    domingo: "domingo",
    segunda: "segunda",
    terca: "terça",
    quarta: "quarta",
    quinta: "quinta",
    sexta: "sexta",
    sabado: "sábado",
  };

  const handleChangeDias = (dia) => {
    const updatedDias = {
      ...diasSelecionados,
      [dia]: !diasSelecionados[dia],
    };
    setDiasSelecionados(updatedDias);

    const selectedDays = Object.keys(updatedDias).filter(
      (dia) => updatedDias[dia]
    );

    const novosHorarios = selectedDays.reduce((acc, dia) => {
      const { horaInicio, horaFim } = disponibilidadePorDia[dia];
      acc[`horario_${dia}`] = `${horaInicio}|${horaFim}`;
      return acc;
    }, {});

    onSaveHorarios(novosHorarios);
  };

  const handleTimeChange = (dia, type, value) => {
    const newDisponibilidade = {
      ...disponibilidadePorDia[dia],
      [type]: value,
    };

    setDisponibilidadePorDia((prev) => ({
      ...prev,
      [dia]: newDisponibilidade,
    }));

    const selectedDays = Object.keys(diasSelecionados).filter(
      (dia) => diasSelecionados[dia]
    );

    const novosHorarios = selectedDays.reduce((acc, dia) => {
      const { horaInicio, horaFim } = disponibilidadePorDia[dia];
      acc[`horario_${dia}`] = `${horaInicio}|${horaFim}`;
      return acc;
    }, {});

    onSaveHorarios(novosHorarios);
  };

  return (
    <div className="container-fluid py-3 rounded overflow-x-hidden overflow-y-auto z-2 m-0">
      <div className="row g-2 mb-5">
        <div className="row g-2">
          <div className="col-sm-6">
            <label className="form-label col w-100">Dias da semana:</label>
            <div className="btn-group" role="group">
              {Object.keys(diasSelecionados).map((dia, index) => (
                <label
                  key={index}
                  className={`btn btn-outline-vk fs-5 btn-md ${
                    diasSelecionados[dia] ? "active" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    className="btn-check"
                    checked={diasSelecionados[dia]}
                    onChange={() => handleChangeDias(dia)}
                  />
                  {diaMappingDisplay[dia].charAt(0).toUpperCase() +
                    diaMappingDisplay[dia].slice(1)}{" "}
                </label>
              ))}
            </div>
          </div>
        </div>

        {Object.keys(diasSelecionados).map(
          (dia) =>
            diasSelecionados[dia] && (
              <div key={dia}>
                <h5 className="text-capitalize">{diaMappingDisplay[dia]}</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="horaInicio" className="form-label">
                      Hora Início:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaInicio"
                      value={disponibilidadePorDia[dia].horaInicio}
                      onChange={(e) =>
                        handleTimeChange(dia, "horaInicio", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="horaFim" className="form-label">
                      Hora Fim:
                    </label>
                    <input
                      type="time"
                      className="form-control input-md"
                      name="horaFim"
                      value={disponibilidadePorDia[dia].horaFim}
                      onChange={(e) =>
                        handleTimeChange(dia, "horaFim", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <hr />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default HorariosAtendimento;
