import React from "react";
export const TipoVendaCheckboxes = ({ tipoVenda, handleChange }) => (
  <div className="row">
    {["todos", "delivery", "retirada", "encomenda", "consumir", "cardapio"].map(
      (tipo, index) => (
        <div className="col-6" key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name={`tipo_venda.${tipo}`}
              id={`checkbox-${tipo}`}
              checked={tipoVenda[tipo] === 1}
              onChange={handleChange}
              aria-label={`Tipo de venda: ${tipo}`}
            />
            <label htmlFor={`checkbox-${tipo}`} className="form-check-label">
              {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
            </label>
          </div>
        </div>
      )
    )}
  </div>
);
