import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
} from "@react-google-maps/api";
import InputMask from "react-input-mask";
import { geocodeAddress } from "./geocodeUtils";
import { toast } from "react-hot-toast";

const TaxaEntregaGeo = ({ taxa, onChangeLocation }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState(taxa.distancia || 1000);
  const [searchLocation, setSearchLocation] = useState(taxa.cep_inicio || "");
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [cepError, setCepError] = useState("");
  const [loadingGeocode, setLoadingGeocode] = useState(false);

  const defaultPosition = { lat: -23.0882, lng: -47.2234 };

  const { isLoaded: mapsLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAaRG_Q93Makyp9Dt_n_r_fyYGd3g91yIo",
  });

  useEffect(() => {
    setIsLoaded(mapsLoaded);
  }, [mapsLoaded]);

  const isCepValid = (cep) => {
    const cepRegex = /^[0-9]{5}-[0-9]{3}$/;
    return cepRegex.test(cep);
  };

  const handleSearch = async () => {
    if (!isCepValid(searchLocation)) {
      setCepError(
        "CEP inválido. Por favor, insira um CEP no formato 12345-678."
      );
      return;
    }

    setCepError("");
    setLoadingGeocode(true);
    try {
      const result = await geocodeAddress(searchLocation);

      if (result) {
        setSearchedLocation(result);
        onChangeLocation(result);
        toast.success("Geolocalização encontrada para o CEP.");
      } else {
        toast.error("Geolocalização não encontrada para o CEP.");
      }
    } catch (error) {
      toast.error("Geolocalização não encontrada para o CEP fornecido.");
      console.error("Erro ao obter coordenadas:", error);
    } finally {
      setLoadingGeocode(false);
    }
  };

  const locationToUse = searchedLocation || defaultPosition;

  return (
    <div className="row">
      <div className="col-sm-4 mt-2">
        <label className="form-label" htmlFor="id_taxa">
          Id.Taxa:
        </label>
        <input
          type="text"
          name="id_taxa"
          defaultValue={taxa.id_taxa}
          className="form-control input-sm"
          placeholder=""
          disabled
        />
      </div>
      <div className="col-sm-2 mt-2">
        <label className="form-label" htmlFor="valor_taxa">
          Vr.Taxa:
        </label>
        <input
          type="number"
          name="valor_taxa"
          defaultValue={taxa.valor_taxa}
          className="form-control input-sm"
          placeholder=""
          required
        />
      </div>
      <div className="col-sm-6 mt-2">
        <label className="form-label" htmlFor="tempo_entrega">
          Tempo de entrega (em minutos):
        </label>
        <input
          type="number"
          name="tempo_entrega"
          defaultValue={taxa.tempo_entrega}
          className="form-control input-sm"
          placeholder=""
          required
        />
      </div>
      <div className="col-sm-6 mt-2">
        <label className="form-label" htmlFor="bairro">
          Localidade"Região":
        </label>
        <input
          type="text"
          name="bairro"
          defaultValue={taxa.bairro}
          className="form-control input-sm"
          placeholder=""
          required
        />
      </div>
      <div className="col-sm-6 mt-2">
        <label htmlFor="cep_inicio" className="form-label">
          Localização (CEP)
        </label>
        <div className="input-group p-0 mt-0">
          <InputMask
            mask="99999-999"
            type="text"
            name="cep_inicio"
            id="cep_inicio"
            required
            className="form-control input-sm"
            placeholder="Digite o CEP"
            value={searchLocation}
            onChange={(e) => setSearchLocation(e.target.value)}
            onBlur={() => handleSearch()}
          />
          <button
            type="button"
            onClick={handleSearch}
            className="btn btn-sm btn-vk"
            disabled={loadingGeocode}
            aria-label="Pesquisar Localização pelo CEP"
          >
            {loadingGeocode ? "Buscando..." : "Pesquisar"}
          </button>
        </div>
        {cepError && (
          <div className="alert alert-danger mt-2" role="alert">
            {cepError}
          </div>
        )}
      </div>
      <div className="col-12 mt-2">
        <label className="form-label" htmlFor="distancia">
          Raio (Km)
        </label>
        <select
          name="distancia"
          className="form-select"
          value={selectedRadius}
          onChange={(e) => setSelectedRadius(Number(e.target.value))}
        >
          <option value={1000}>1 quilômetro</option>
          <option value={2000}>2 quilômetros</option>
          <option value={5000}>5 quilômetros</option>
          <option value={10000}>10 quilômetros</option>
          <option value={20000}>20 quilômetros</option>
          <option value={40000}>40 quilômetros</option>
          <option value={60000}>60 quilômetros</option>
          <option value={80000}>80 quilômetros</option>
          <option value={100000}>100 quilômetros</option>
          <option value={250000}>250 quilômetros</option>
          <option value={500000}>500 quilômetros</option>
        </select>
      </div>

      <div className="container-fluid mapaSvg mt-3">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ height: "300px", width: "100%" }}
            center={locationToUse}
            zoom={14}
          >
            <Marker position={locationToUse} />
            <Circle
              center={locationToUse}
              radius={selectedRadius}
              options={{
                strokeColor: "#FF5722",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF7043",
                fillOpacity: 0.35,
              }}
            />
          </GoogleMap>
        ) : (
          <p>Carregando mapa...</p>
        )}
      </div>
    </div>
  );
};

TaxaEntregaGeo.propTypes = {
  taxa: PropTypes.object.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
};

export default TaxaEntregaGeo;
