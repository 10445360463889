import { useState, useCallback, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast from "react-hot-toast";

export const useLoadPedidos = (estabelecimento) => {
  const [pedidos, setPedidos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadPedidos = useCallback(() => {
    if (!estabelecimento) return;

    setIsLoading(true);
    PrestAPI.get("Pedidos", `estab=${estabelecimento.id_estabelecimento}`)
      .then((res) => {
        setPedidos(res.pedidos);
        setIsLoading(false);
      })
      .catch((erro) => {
        toast.error("Erro ao carregar pedidos.");
        setIsLoading(false);
      });
  }, [estabelecimento]);
  useEffect(() => {
    loadPedidos();
  }, []);

  return { pedidos, loadPedidos, isLoading };
};
