import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Pedidos.css";
import { formatCurrencyBRL } from "../../utils/formatters";
import { X, Check2 } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import PedidoSelecionado from "./PedidoSelecionado";
import useMediaQuery from "../../utils/useMediaQuery";
import { usePedidos } from "../../hooks/Pedidos/useBuscaPedidos";
import { useLoadPedidos } from "../../hooks/Pedidos/useLoadPedidos";

const statusMap = {
  PENDENTE: "CONFIRMADO",
  CONFIRMADO: "ENVIADO",
  ENVIADO: "ENTREGUE",
};

const Pedidos = ({
  estabelecimento,
  setConfirmaPedido,
  unsetConfirmaPedido,
}) => {
  const [pedido, setPedido] = useState({});
  const [documentoHide, setDocumentoHide] = useState("");
  const [itens, setItens] = useState([]);
  const [modoPedido, setModoPedido] = useState("ABERTO");
  const [subtotal, setSubtotal] = useState(0);
  const isAboveMediumScreens = useMediaQuery("(min-width:768px)");
  const [expandedPedidoId, setExpandedPedidoId] = useState(false);
  const [loadingPedido, setLoadingPedido] = useState(false);
  const { pedidos } = useLoadPedidos(estabelecimento);

  const fetchPedido = async (pedido) => {
    setLoadingPedido(true);
    try {
      const value = await PrestAPI.get(
        "Pedidos",
        `id_pedido=${pedido.id_pedido}`
      );
      let maskedDocumento = "";
      if (pedido.documento) {
        maskedDocumento =
          pedido.documento.length === 14
            ? pedido.documento.substr(0, 5) + "**.***-**"
            : pedido.documento.substr(0, 4) + "**.***/****-**";
      }
      setPedido(pedido);
      setItens(value);
      setDocumentoHide(maskedDocumento);
    } catch (error) {
      console.error("Erro ao carregar pedido:", error);
      toast.error("Erro ao carregar o pedido.");
    } finally {
      setLoadingPedido(false);
    }
  };

  const calSubtotal = () => {
    const subtotalValue = itens.reduce((soma, item) => {
      const totalSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + totalSubItens) * item.quantidade;
      return soma + preco;
    }, 0);
    setSubtotal(subtotalValue);
  };

  const updateStatusPedido = async (pedido, statusPedido) => {
    try {
      pedido.status_pedido = statusPedido;

      const response = await PrestAPI.update(
        "Pedido",
        `?id_pedido=${pedido.id_pedido}`,
        pedido
      );

      if (response.status === 200 || response.status === 204) {
        setPedido(pedido);

        const pedidosResponse = await PrestAPI.get(
          "Pedidos",
          `estab=${estabelecimento.id_estabelecimento}`
        );

        const pendingOrders = pedidosResponse.pedidos.filter(
          (pedido) => pedido.status_pedido === "PENDENTE"
        );

        if (pendingOrders.length) {
          setConfirmaPedido();
        } else {
          unsetConfirmaPedido();
        }
      } else {
        toast.error("Erro ao atualizar o status do pedido.");
      }
    } catch (error) {
      const errorMessage =
        error.response.data.error || "Erro ao atualizar o status do pedido.";
      toast.error(errorMessage);
      console.error("Erro ao atualizar o pedido:", error);
    }
  };

  const renderStatus = (statusPedido, isTitle = false) => {
    const statusConfig = {
      CONFIRMADO: { border: "1px solid DeepSkyBlue", text: "Confirmado" },
      ENVIADO: { border: "1px solid gray", text: "Enviado" },
      ENTREGUE: { border: "1px solid green", text: "Entregue" },
      CANCELADO: { border: "1px solid red", text: "Cancelado" },
      default: { border: "1px solid orange", text: "Pendente" },
    };

    const { border, text } = statusConfig[statusPedido] || statusConfig.default;

    return isTitle ? (
      <h4
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
          margin: "20px, 0px, 10px",
        }}
      >
        {text}
      </h4>
    ) : (
      <div
        className="col-12"
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    );
  };

  const quantPedidosFechados = () => {
    const pedidosFechados = pedidos.filter(
      (pedido) =>
        pedido.status_pedido === "ENTREGUE" ||
        pedido.status_pedido === "CANCELADO"
    );
    return pedidosFechados.length;
  };

  const quantPedidosAbertos = () => {
    const pedidosAbertos = pedidos.filter(
      (pedido) =>
        pedido.status_pedido !== "ENTREGUE" &&
        pedido.status_pedido !== "CANCELADO"
    );
    return pedidosAbertos.length;
  };

  const verificaStatus = (pedido) => {
    return modoPedido === "ABERTO"
      ? !["CANCELADO", "ENTREGUE"].includes(pedido.status_pedido)
      : ["CANCELADO", "ENTREGUE"].includes(pedido.status_pedido);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="card col-sm-4 col-12 overflow-auto"
          style={{
            height: "85vh",
            borderRadius: "3px 0px 0px 3px",
          }}
        >
          <div className="row mb-4 card-header">
            <div className="col-6 text-center">
              <button
                className="btn w-100 btn-light bg-light"
                style={{
                  borderBottom: `${
                    modoPedido === "ABERTO" ? "solid 3px #ff5722" : ""
                  }`,
                }}
                onClick={() => setModoPedido("ABERTO")}
              >
                {modoPedido === "ABERTO" ? (
                  <b style={{ color: "#ff5722" }}>Aberto</b>
                ) : (
                  <>Aberto</>
                )}
              </button>
              <span>{quantPedidosAbertos()}</span>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn w-100 btn-light bg-light"
                style={{
                  borderBottom: `${
                    modoPedido !== "ABERTO" ? "solid 3px #ff5722" : ""
                  }`,
                }}
                onClick={() => setModoPedido("FINALIZADO")}
              >
                {modoPedido !== "ABERTO" ? (
                  <b style={{ color: "#ff5722" }}>Finalizado</b>
                ) : (
                  <span>Finalizado</span>
                )}
              </button>
              <span>{quantPedidosFechados()}</span>
            </div>
          </div>

          {pedidos &&
            pedidos
              .filter((pedido_filter) => verificaStatus(pedido_filter))
              .map((pedido_item) => (
                <>
                  <div
                    className={`card clickable pedido mt-3 ${
                      pedido_item.id_pedido === pedido.id_pedido &&
                      "pedidoSelecionado"
                    }`}
                    key={pedido_item.id_pedido}
                    onClick={() => {
                      fetchPedido(pedido_item);
                      setExpandedPedidoId(
                        expandedPedidoId === pedido_item.id_pedido
                          ? null
                          : pedido_item.id_pedido
                      );
                    }}
                    style={{
                      WebkitUserSelect: "none",
                      MozUserSelect: "none",
                      MsUserSelect: "none",
                      userSelect: "none",
                      backgroundColor: `${
                        pedido_item.status_pedido === "PENDENTE"
                          ? "#fcee9f"
                          : pedido_item.status_pedido === "ENTREGUE"
                          ? "#abfc8b"
                          : pedido_item.status_pedido === "CANCELADO"
                          ? "#fc9797"
                          : ""
                      }`,
                    }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-2">{pedido_item.id_pedido}</div>
                        <div className="col-5 p-0">
                          {pedido_item.nome_cliente}
                        </div>
                        <div className="col-5">
                          <div className="col-12">
                            {renderStatus(pedido_item.status_pedido)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {expandedPedidoId === pedido_item.id_pedido &&
                    !isAboveMediumScreens && (
                      <div className="card p-2 position-relative">
                        {loadingPedido ? (
                          <div className="loader" id="loader">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        ) : (
                          <PedidoSelecionado
                            pedido={pedido}
                            subtotal={subtotal}
                            documentoHide={documentoHide}
                            itens={itens}
                            renderStatus={renderStatus}
                            updateStatusPedido={updateStatusPedido}
                          />
                        )}
                      </div>
                    )}
                </>
              ))}
        </div>
        {pedido.id_pedido && isAboveMediumScreens && (
          <div
            className="card col-sm-8 col-12 pb-3 overflow-auto"
            style={{
              height: "85vh",
              padding: "30px 80px 30px 80px",
              borderRadius: "0px 3px 3px 0px",
            }}
          >
            {loadingPedido ? (
              <div className="loader justify-content-center" id="loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <PedidoSelecionado
                pedido={pedido}
                subtotal={subtotal}
                documentoHide={documentoHide}
                itens={itens}
                renderStatus={renderStatus}
                updateStatusPedido={updateStatusPedido}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pedidos;
