import { useState, useEffect, useCallback } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast from "react-hot-toast";
import som from "../../assets/audios/audio.mp3";

const audio = new Audio(som);

export const useBuscaPedidos = (estabelecimento) => {
  const [pedidosConfirmar, setPedidosConfirmar] = useState(false);

  const buscaPedidos = useCallback(() => {
    if (!estabelecimento) return;

    PrestAPI.get("Pedidos", `estab=${estabelecimento.id_estabelecimento}`)
      .then((res) => {
        const hasPendingOrders = res.pedidos.some(
          (pedido) => pedido.status_pedido === "PENDENTE"
        );

        if (hasPendingOrders) {
          toast(`Você tem pedidos para confirmar!`, {
            icon: "🛎️",
            id: "Pedidos",
          });
          audio.play();
          setPedidosConfirmar(true);
        } else {
          setPedidosConfirmar(false);
        }
      })
      .catch(() => {
        toast.error("Erro ao buscar pedidos.");
      });
  }, [estabelecimento]);

  useEffect(() => {
    if (estabelecimento) {
      const interval = setInterval(() => {
        buscaPedidos();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [estabelecimento, buscaPedidos]);

  return { pedidosConfirmar, buscaPedidos };
};
