import React, { Component } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import serializeForm from "form-serialize";
import TaxaEntregaGeo from "./TaxaEntregaGeo";

class TaxaEntregaForm extends Component {
  static propTypes = {
    taxa: PropTypes.object,
    saveTaxa: PropTypes.func.isRequired,
    isNewTaxa: PropTypes.bool.isRequired,
  };

  state = {
    tabIndex: 0,
    usaGeo: false,
    usaRange: false,
    usaBairro: false,
    location: { lat: 0, lng: 0 },
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    try {
      const data = serializeForm(form, { hash: true });

      const location = this.state.location;
      const currentTax = this.props.taxa;

      data.id_estabelecimento = Number.parseInt(
        currentTax.id_estabelecimento,
        10
      );
      data.valor_taxa = parseFloat(data.valor_taxa);
      data.distancia = parseFloat(data.distancia);
      data.latitude = location.lat ? location.lat : currentTax.latitude;
      data.longitude = location.lng ? location.lng : currentTax.longitude;

      const idTaxa = currentTax.id_taxa;

      if (this.props.saveTaxa) {
        await this.props.saveTaxa(idTaxa, data);
      }
    } catch (error) {
      console.error("Erro ao salvar a taxa:", error);
    }
  };

  componentDidMount() {
    const data = this.props.taxa;
    const isNewTaxa = this.props.isNewTaxa;

    const usaGeo =
      !isNewTaxa &&
      data.lat !== 0 &&
      data.lng !== 0 &&
      data.cep_inicio !== "" &&
      data.cep_fim === "";
    const usaRange = data.cep_fim !== "";
    const usaBairro = !usaGeo && !usaRange;

    this.setState({ usaRange, usaGeo, usaBairro });
  }

  render() {
    const { taxa, isNewTaxa } = this.props;
    const { tabIndex, usaRange, usaGeo, usaBairro } = this.state;

    return (
      <div className="container-fluid">
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => this.setState({ tabIndex })}
        >
          <TabList className="container">
            {isNewTaxa ? (
              <>
                <Tab>Adicionar por bairro</Tab>
                {/* <Tab>Adicionar por limite de CEP</Tab> */}
                <Tab>Adicionar por localização</Tab>
              </>
            ) : (
              <>
                {/* {usaRange && <Tab>Editar limite de CEP</Tab>} */}
                {usaGeo && <Tab>Editar localização</Tab>}
                {usaBairro && <Tab>Editar Bairro</Tab>}
              </>
            )}
          </TabList>

          {isNewTaxa || usaBairro ? (
            <TabPanel className="container">
              <div className="col-xl-8 row mx-auto justify-content-center">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="fs-4">
                      Taxa de Entrega (Bairro) - Cadastro
                    </h3>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to="/taxa_entrega-add"
                      className="btn btn-vk w-100"
                      onClick={() => this.props.setNovaTaxa()}
                    >
                      Adicionar Taxa
                    </Link>
                  </div>
                </div>

                <form onSubmit={this.handleSubmit}>
                  <div className="row mt-2 align-items-end">
                    <div className="col-sm-12" hidden>
                      <label className="form-label">Id.Estabelecimento:</label>
                      <input
                        type="text"
                        name="id_estabelecimento"
                        defaultValue={taxa.id_estabelecimento || 0}
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-label">Id.Taxa:</label>
                      <input
                        type="text"
                        name="id_taxa"
                        defaultValue={taxa.id_taxa}
                        className="form-control input-sm"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-label">Vr.Taxa:</label>
                      <input
                        type="number"
                        name="valor_taxa"
                        defaultValue={taxa.valor_taxa}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-label">
                        Tempo de entrega (em minutos):
                      </label>
                      <input
                        type="number"
                        name="tempo_entrega"
                        defaultValue={taxa.tempo_entrega}
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="form-label">Bairro:</label>
                      <input
                        type="text"
                        name="bairro"
                        defaultValue={taxa.bairro}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="form-label">Cidade:</label>
                      <input
                        type="text"
                        name="cidade"
                        defaultValue={taxa.cidade}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-sm-3">
                      <Link
                        to="/taxa_entrega-list"
                        className="btn btn-outline-secondary btn-lg w-100"
                      >
                        Voltar
                      </Link>
                    </div>
                    <div className="col-sm-9">
                      <button className="btn btn-vk btn-lg w-100">
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
          ) : null}

          {/* {isNewTaxa || usaRange ? (
            <TabPanel className="container">
              <div className="col-xl-8 mx-auto justify-content-center">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="fs-4">
                      Taxa de Entrega (Limite de CEP) - Cadastro
                    </h3>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to="/taxa_entrega-add"
                      className="btn btn-vk w-100"
                      onClick={() => this.props.setNovaTaxa()}
                    >
                      Adicionar Taxa
                    </Link>
                  </div>
                </div>

                <form onSubmit={this.handleSubmit}>
                  <div className="row mt-2">
                    <div className="col-12" hidden>
                      <label className="form-label">Id.Estabelecimento:</label>
                      <input
                        type="text"
                        name="id_estabelecimento"
                        defaultValue={taxa.id_estabelecimento}
                        className="form-control input-sm"
                        placeholder=""
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-label">Id.Taxa:</label>
                      <input
                        type="text"
                        name="id_taxa"
                        defaultValue={taxa.id_taxa}
                        className="form-control input-sm"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-2 mb-2">
                      <label className="form-label">Vr.Taxa:</label>
                      <input
                        type="number"
                        name="valor_taxa"
                        defaultValue={taxa.valor_taxa}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="form-label">
                        Tempo de entrega (em minutos):
                      </label>
                      <input
                        type="number"
                        name="tempo_entrega"
                        defaultValue={taxa.tempo_entrega}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>

                    <div className="col-sm-6 mb-2">
                      <label className="form-label">CEP Inicial:</label>
                      <input
                        type="text"
                        name="cep_inicio"
                        className="form-control input-sm"
                        placeholder=""
                        defaultValue={taxa.cep_inicio}
                      />
                    </div>

                    <div className="col-sm-6 mb-2">
                      <label className="form-label">CEP Final:</label>
                      <input
                        type="text"
                        name="cep_fim"
                        className="form-control input-sm"
                        placeholder=""
                        defaultValue={taxa.cep_fim}
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="form-label">Cidade:</label>
                      <input
                        type="text"
                        name="cidade"
                        defaultValue={taxa.cidade}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="form-label">Bairro:</label>
                      <input
                        type="text"
                        name="bairro"
                        defaultValue={taxa.bairro}
                        className="form-control input-sm"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="row mt-2 g-2">
                    <div className="col-sm-3">
                      <Link
                        to="/taxa_entrega-list"
                        className="btn btn-outline-secondary btn-lg w-100"
                        onClick={() => this.setState({ usaRange: false })}
                      >
                        Voltar
                      </Link>
                    </div>
                    <div className="col-sm-9">
                      <button className="btn btn-vk btn-lg w-100">
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
          ) : null} */}

          {isNewTaxa || usaGeo ? (
            <TabPanel className="container">
              <div className="col-xl-8 mx-auto justify-content-center">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="fs-4">Taxa de Entrega (Por Km)</h3>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to="/taxa_entrega-add"
                      className="btn btn-vk w-100"
                      onClick={() => this.props.setNovaTaxa()}
                    >
                      Adicionar Taxa
                    </Link>
                  </div>
                </div>

                <form onSubmit={this.handleSubmit}>
                  <TaxaEntregaGeo
                    taxa={taxa}
                    saveTaxa={() => {}}
                    isNewTaxa={false}
                    onChangeLocation={(params) => {
                      this.setState({ location: params });
                    }}
                  />

                  <div className="row">
                    <div className="col-sm-3">
                      <Link
                        to="/taxa_entrega-list"
                        className="btn btn-outline-secondary btn-lg w-100"
                        onClick={() => this.setState({ usaRange: false })}
                      >
                        Voltar
                      </Link>
                    </div>
                    <div className="col-sm-9">
                      <button className="btn btn-vk btn-lg w-100">
                        Salvar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

export default TaxaEntregaForm;
