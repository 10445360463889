import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import InputField from "../../components/InputField/InputField";
import InputMask from "react-input-mask";
import {
  formatCNPJ,
  formatIE,
  formatPhone,
  formatTime,
} from "../../utils/formatters";
import { validateImage, validateFields } from "../../utils/validators";
import HorariosAtendimento from "../../components/Horarios/Horarios";
import useCategorias from "../../hooks/Establecimentos/useCategoriasEstabelecimento";
import useUploadImage from "../../hooks/imagens/useUploadImage";
import useUpdateEstabelecimento from "../../hooks/Establecimentos/useUpdateEstabelecimento";
import { TipoVendaCheckboxes } from "./Components/TipoVendaCheckboxes";
import ImageInput from "../../components/Imagens/ImageInput";

const tipoVendaPlataforma = [
  "anotejá subdominio",
  "anotejá multiloja subdominio",
  "anotejá dominio cliente",
  "anotejá multiloja dominio cliente",
];

const tipoExibicaoProduto = [
  "desativar carrinho e modal o botão adicionar",
  "exibir preço e habilitar carrinho somente cliente logado",
  "não exibir preço, e habilitar carrinho somente cliente logado",
];

const FormEstabelecimento = ({ estabelecimentoId, isEditing, onCancel }) => {
  const [estabelecimento, setEstabelecimento] = useState({
    razao_social: "",
    nome_fantasia: "",
    cnpj: "",
    ie: "",
    segmento: "",
    senha: "",
    email: "",
    email_contato: "",
    telefone: "",
    telefone_contato: "",
    bairro: "",
    logradouro: "",
    numero: "",
    municipio: "",
    descricao: "",
    uf: "",
    tempo_minimo_entrega: 0,
    tipo_venda_plataforma: 0,
    permite_pagamento_online: 0,
    permite_uso_vempraka: 0,
    exibe_preco_apenas_cliente_logado: 0,
    habilita_cadastro_proprio_cliente: 0,
    desabilita_todos_tipo_venda: 0,
    habilita_marketplace_vempraka: 0,
    status: 0,
    imagem: null,
    complemento: "",
    horario: {
      horario_Domingo: "08:00|20:00",
      horario_Segunda: "08:00|20:00",
      horario_Terca: "08:00|20:00",
      horario_Quarta: "08:00|20:00",
      horario_Quinta: "08:00|20:00",
      horario_Sexta: "08:00|20:00",
      horario_Sabado: "08:00|20:00",
    },
    tipo_venda: {
      todos: 0,
      delivery: 0,
      retirada: 0,
      encomenda: 0,
      consumir: 0,
      cardapio: 0,
    },
  });
  const [imageFile, setImageFile] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [isLoadingEstabelecimento, setIsLoadingEstabelecimento] =
    useState(false);
  const { updateEstabelecimento, isUpdating } = useUpdateEstabelecimento();

  const { categorias, isLoadingCategorias } = useCategorias();
  const { uploadImage, isLoadingImage } = useUploadImage();

  useEffect(() => {
    if (estabelecimentoId) {
      getEstabelecimento(estabelecimentoId);
    }
  }, [estabelecimentoId]);
  const handleHorariosChange = (newHorarios) => {
    setEstabelecimento((prev) => ({
      ...prev,
      horario: newHorarios,
    }));
  };

  const getEstabelecimento = async (id) => {
    setIsLoadingEstabelecimento(true);
    try {
      const res = await PrestAPI.get("Estabelecimento", `estab=${id}`);
      if (!res) throw new Error("Estabelecimento não encontrado");

      const mappedTipoVenda = {
        todos: res.todos || 0,
        delivery: res.permite_pagamento_online || 0,
        retirada: res.retirada || 0,
        encomenda: res.permite_agendamento || 0,
        consumir: res.consumir || 0,
        cardapio: res.habilita_modo_cardapio || 0,
      };

      setEstabelecimento({
        ...estabelecimento,
        ...res,
        tipo_venda: mappedTipoVenda,
      });
    } catch (error) {
      toast.error(
        "Erro ao buscar o estabelecimento. Por favor, tente novamente."
      );
    } finally {
      setIsLoadingEstabelecimento(false);
    }
  };

  const handleFileChange = (url) => {
    if (validateImage(url)) {
      setImageFile(url);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const val = type === "checkbox" ? (checked ? 1 : 0) : value;

    setEstabelecimento((prev) => {
      const keys = name.split(".");
      if (keys.length === 1) {
        return { ...prev, [name]: val };
      } else {
        const updated = { ...prev };
        let nested = updated;
        for (let i = 0; i < keys.length - 1; i++) {
          nested[keys[i]] = { ...nested[keys[i]] };
          nested = nested[keys[i]];
        }
        nested[keys[keys.length - 1]] = val;
        return updated;
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateFields(estabelecimento);
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    const preparedEstabelecimento = await prepareEstabelecimentoData();
    if (preparedEstabelecimento) {
      console.log("Estabelecimento:", preparedEstabelecimento);
      console.log("imagem:", estabelecimento.imagem);
      updateEstabelecimento(preparedEstabelecimento);
    }
  };
  const prepareEstabelecimentoData = async () => {
    let updatedEstabelecimento = estabelecimento;

    console.log("imagem:", imageFile);

    if (imageFile) {
      const imageUrl = await uploadImage(imageFile);
      if (imageUrl) {
        updatedEstabelecimento = {
          ...updatedEstabelecimento,
          imagem: imageUrl,
        };
      }
    }

    const apiData = {
      ...updatedEstabelecimento,
      cnpj: updatedEstabelecimento.cnpj.replace(/\D/g, ""),
      ie: updatedEstabelecimento.ie.replace(/\D/g, ""),
      permite_agendamento: updatedEstabelecimento.tipo_venda.encomenda,
      habilita_modo_cardapio: updatedEstabelecimento.tipo_venda.cardapio,
      permite_pagamento_online: updatedEstabelecimento.tipo_venda.delivery,
    };

    return {
      ...apiData,
    };
  };

  if (isLoadingEstabelecimento || isLoadingCategorias) {
    return (
      <div className="loader" id="loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <form className="form-horizontal row" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label>Razão social</label>
          <input
            className="form-control"
            type="text"
            name="razao_social"
            placeholder="Digite a razão social"
            value={estabelecimento.razao_social}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-6">
          <label className="form-label">Nome fantasia</label>
          <input
            className="form-control"
            type="text"
            name="nome_fantasia"
            placeholder="Digite o nome fantasia"
            value={estabelecimento.nome_fantasia}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-4">
          <label className="form-label">CNPJ</label>
          <InputMask
            className="form-control"
            type="text"
            name="cnpj"
            mask="99.999.999/9999-99"
            placeholder="Digite o CNPJ"
            value={estabelecimento.cnpj}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Inscrição Estadual</label>
          <InputMask
            className="form-control"
            type="text"
            name="ie"
            mask="999.999.999.999"
            placeholder="Digite a inscrição estadual"
            value={estabelecimento.ie}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="segmento" className="form-label">
            Segmento
          </label>
          <select
            className="form-select"
            id="segmento"
            name="segmento"
            value={estabelecimento.segmento}
            onChange={handleChange}
            required
          >
            <option value="">Selecione o segmento</option>
            {categorias.map((categoria, index) => (
              <option key={index} value={categoria.nome}>
                {categoria.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12">
          <div className="col-md-6">
            <label className="form-label">Descrição</label>
            <input
              className="form-control"
              type="text"
              name="descricao"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row col-md-9">
          <div className="col-md-4">
            <label className="form-label">Telefone</label>
            <InputMask
              className="form-control"
              type="text"
              name="telefone"
              mask="(99) 99999-9999"
              placeholder="(99) 99999-9999"
              value={estabelecimento.telefone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Email</label>
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder="Digite o email"
              value={estabelecimento.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Senha</label>
            <input
              className="form-control"
              type="text"
              name="senha"
              placeholder="Digite o email"
              value={estabelecimento.senha}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Contato</label>
            <input
              className="form-control"
              type="text"
              name="contato"
              placeholder="Digite o nome do contato"
              value={estabelecimento.contato}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Telefone de contato</label>
            <InputMask
              className="form-control"
              type="text"
              name="telefone_contato"
              mask="(99) 99999-9999"
              placeholder="(99) 99999-9999"
              value={estabelecimento.telefone_contato}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Email de contato</label>
            <input
              className="form-control"
              type="text"
              name="email_contato"
              placeholder="Digite o email de contato"
              value={estabelecimento.email_contato}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <ImageInput
            defaultValue={estabelecimento.imagem}
            onImageChange={handleFileChange}
          />
        </div>

        <div className="col-md-6">
          <label className="form-label">Bairro</label>
          <input
            className="form-control"
            type="text"
            name="bairro"
            placeholder="Digite o bairro"
            value={estabelecimento.bairro}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Logradouro</label>
          <input
            className="form-control"
            type="text"
            name="logradouro"
            placeholder="Digite o logradouro"
            value={estabelecimento.logradouro}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-8">
          <label className="form-label">Complemento</label>
          <input
            className="form-control"
            type="text"
            name="complemento"
            placeholder="Digite o complemento"
            value={estabelecimento.complemento}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">CEP</label>
          <input
            className="form-control"
            type="text"
            name="cep"
            placeholder="Digite o CEP"
            value={estabelecimento.cep}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">Número</label>
          <input
            className="form-control"
            type="text"
            name="numero"
            placeholder="Digite o número"
            value={estabelecimento.numero}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">Cidade</label>
          <input
            className="form-control"
            type="text"
            name="municipio"
            placeholder="Digite a cidade"
            value={estabelecimento.municipio}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-2">
          <label className="form-label">UF</label>
          <input
            className="form-control"
            type="text"
            name="uf"
            placeholder="Digite a UF"
            value={estabelecimento.uf}
            onChange={handleChange}
          />
        </div>

        <div className="row border-bottom border-top py-2 my-2 mx-1">
          <div
            className="my-3 p-3 border border-2 rounded position-relative w-100"
            style={{ maxWidth: "300px" }}
          >
            <span
              className="position-absolute bg-white px-2"
              style={{ top: "-10px", left: "20px" }}
            >
              Tipos de vendas
            </span>
            <TipoVendaCheckboxes
              tipoVenda={estabelecimento.tipo_venda}
              handleChange={handleChange}
            />
          </div>

          <div className="col-lg-6">
            <label htmlFor="exibir_produtos" className="form-label">
              Tipos de Exibição dos produtos
            </label>
            <select
              className="form-select"
              name="exibir_produtos"
              value={estabelecimento.exibir_produtos}
              onChange={handleChange}
            >
              {tipoExibicaoProduto.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row border-bottom py-2 my-2">
          <div className="col-lg-5">
            <label htmlFor="tipo_venda_plataforma" className="form-label">
              Tipos de venda plataforma Anotejá
            </label>
            <select
              className="form-select"
              name="tipo_venda_plataforma"
              value={estabelecimento.tipo_venda_plataforma}
              onChange={handleChange}
            >
              {tipoVendaPlataforma.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-6">
            <label htmlFor="url" className="form-label">
              Url do dominio
            </label>
            <input
              type="text"
              name="url"
              placeholder="Digite a url"
              className="form-control"
            />
          </div>
        </div>
        <div className="w-100 py-2 border-bottom border-light">
          <div
            className="my-3 p-3 border border-2 rounded position-relative w-100"
            style={{ maxWidth: "300px" }}
          >
            <span
              className="position-absolute bg-white px-1"
              style={{ top: "-10px", left: "20px" }}
            >
              Tipos de venda plataforma Vempraka
            </span>
            <div className="row">
              <div className="col-6">
                <div className="form-check align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="vempraka"
                  />
                  <label htmlFor="vempraka" className="form-check-label">
                    Vempraka
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 py-2 border-bottom border-light">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <h5 className="text-center fs-5">Horário de atendimento</h5>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <HorariosAtendimento
                horarios={estabelecimento.horario}
                onSaveHorarios={handleHorariosChange}
              />
            </div>
          </div>
        </div>
        {estabelecimento.tipo_venda.encomenda === 1 && (
          <div className="col-md-2">
            <label className="form-label">Tempo mínimo para entrega:</label>
            <input
              className="form-control"
              type="text"
              name="tempo_minimo_entrega"
              placeholder="Digite o tempo mínimo para entrega"
              value={estabelecimento.tempo_minimo_entrega}
              onChange={handleChange}
            />
          </div>
        )}

        <div className="col-md-4">
          <label className="form-label">Empresa Ativa:</label>
          <div className="row input-group ">
            <label htmlFor="empresa-ativa-sim" className="radio-label">
              <input
                type="radio"
                name="status"
                id="empresa-ativa-sim"
                value={1}
                aria-label="Ativar empresa"
                checked={estabelecimento.status === 1}
                onChange={() =>
                  setEstabelecimento((prevState) => ({
                    ...prevState,
                    status: 1,
                  }))
                }
              />
              Sim
            </label>
            <label htmlFor="empresa-ativa-nao" className="radio-label">
              <input
                type="radio"
                name="status"
                value={0}
                id="empresa-ativa-nao"
                aria-label="Desativar empresa"
                checked={estabelecimento.status === 0}
                onChange={() =>
                  setEstabelecimento((prevState) => ({
                    ...prevState,
                    status: 0,
                  }))
                }
              />
              Não
            </label>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-lg-3">
            <button
              type="submit"
              className="btn btn-vk w-100"
              aria-label={isEditing ? "Salvar Alterações" : "Cadastrar Empresa"}
            >
              {isEditing ? "Salvar Alterações" : "Cadastrar Empresa"}
            </button>
          </div>
          <div className="col-lg-2">
            <button
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={onCancel}
              aria-label="Cancelar e retornar"
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
      <Toaster />
    </React.Fragment>
  );
};

export default FormEstabelecimento;
